

@media only screen and (max-width: 600px) {
  #root {
    #app{  
      padding: 0 !important;
      > header {
        margin-top: 0;
      }

        .contact{
          padding: 0 1% 0 1%;
          .phonesList,.notesList{
            > .phoneLine,.noteLine{
              flex-wrap: wrap;
              justify-content: flex-end;
              
              .lib,.num{
                width: 100%;
              }
            }
            > .noteLine > button {
              order: 3;
            }
          }
        }
      }
    }
}