@import "./mobile.scss";

%materialToCopy {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
  padding: 15px;
  background-color: #474545;
  color: rgb(240, 229, 229);
  font-weight: 100;
  word-wrap: break-word;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
}

#root {
  #app {
    height: 100%;
    display: flex;
    flex-direction: column;

    .contact {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      height: calc(100% - 38px);

      .navButtons {
        position: fixed;
        bottom: 60px;
        right: 2%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        > button {
          margin: 3px;
        }
      }

      .line.MuiFormControl-root {
        margin-top: 5px;
      }

      .line.heightLight .MuiInputBase-root,
      .phoneLine .lib.heightLight .MuiInputBase-root,
      .phoneLine .num.heightLight .MuiInputBase-root,
      .noteLine .lib.heightLight .MuiInputBase-root,
      .noteLine .note.heightLight .MuiInputBase-root {
        color: blue;
      }

      .phonesList,
      .notesList {
        .titleButtonGroup {
          display: flex;
          justify-content: space-between;
          margin: 3px 0px;
        }

        > button.add {
          margin-bottom: 5px;
        }

        > .noteLine {
          flex-wrap: wrap;
          > .note {
            width: 100%;
          }
        }
        > .phoneLine,
        .noteLine {
          margin-bottom: 5px;
          display: flex;
          align-content: stretch;

          padding: 5px;

          .order {
            display: none;
          }
          .lib,
          .num,
          .note {
            flex-grow: 1;
            padding: 5px;
          }
        }
      }

      .contactContainer {
        padding: 0 3px 0 3px;
        overflow-y: auto;
      }

      .bottomBar {
        margin-top: auto;

        .editButtons {
          padding: 3px;
          margin-bottom: 3px;
          display: flex;
          justify-content: space-around;
        }

        .search {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          width: 100%;

          .input {
            flex: 1;
          }

          .icons {
            padding: 10;
          }

          .MuiChip-root {
            margin-right: 5px;
          }
        }
      }
    }

    > .login {
      margin-top: 20px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .explication {
        width: 100%;
      }

      .errorPaper {
        background-color: #f44336;
        color: #fff;
        width: 80%;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      > .password {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    #devicePage {
      #request,
      #answerArea {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > * {
          margin: 10px;
        }
      }
      .answer {
        @extend %materialToCopy;
      }
    }

    > .appBar {
      > .buttons {
        min-height: 38px;
        display: flex;
        overflow-x: auto;
        justify-items: end;
        justify-content: space-around;
      }
    }

    > .needToBeAuth {
      > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > button {
          margin-top: 10px;
        }
      }
      margin: 20px;
      padding: 15px;

      > .invitationRequest {
        @extend %materialToCopy;
        // margin-top: 15px;
        // margin-bottom: 15px;
        // border-radius: 4px;
        // text-align: center;
        // padding: 15px;
        // background-color: #474545;
        // color: rgb(240, 229, 229);
        // font-weight: 100;
        // word-wrap: break-word;
      }

      > div {
        display: flex;
        justify-content: center;

        > button {
          margin: 15px;
        }
      }
    }

    > .settingPage,
    > .importExportPage {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
    }
  }
}

#delete-dialog-description {
  > span {
    font-weight: 900;
  }
}
